import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";

function Disclaimer() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

  return (
    <div className="disclaimer">
        <div className="container">
            <div className="col-md-8">
                <div className="row">
                    <h2><img src='img/18.png' width="30" alt="18+ only"/>  {landingPageData ? landingPageData.Disclaimer?.title : "Loading"}</h2>
                    <p>{landingPageData ? landingPageData.Disclaimer?.text : "Loading"}</p>
            <div className="col-md-6 pt-20">
                <a className="pr20" href="https://www.gamcare.org.uk/" target='_blank' rel="nofollow noreferrer">
                    <img src="img/gamCare.png" width="70" alt="Gam Care" />
                </a>
                <a href="https://www.gambleaware.org/" target='_blank' rel="nofollow noreferrer">
                    <img src="img/BeAware.png" width="250" alt="Be gamble aware" />
                </a>
            </div>
                </div>
            </div>
            <div className="col-md-3 col-md-offset-1">
                <h3>Have a question?</h3>
                <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-phone"></i> Phone:
                      </span>{" "}
                      {landingPageData ? landingPageData.Contact?.phone : "loading"}
                    </p>
                  </div>
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i> Email:
                      </span>{" "}
                      {landingPageData ? landingPageData.Contact?.email : "loading"}
                    </p>
                  </div>
            </div>
        </div>
    </div>
  )
}

export default Disclaimer