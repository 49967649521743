import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/navigation";
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import Footer from "./components/footer";
import Policy from "./pages/Policy";
import Terms from "./pages/Terms";
import Games from "./pages/Games";
import AgeVerificationPopup from "./components/AgeVerificationPopup";
import Disclaimer from "./components/disclaimer";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";
import AboutPage from "./pages/AboutPage";

const pages = [
  { to: '/', title: 'Home', component: <Home/> },
  { to: '/about-us', title: 'Home', component: <AboutPage/> },
  { to: '/contact', title: 'Contact us', component: <ContactPage />},
  { to: '/privacy-policy', title: 'Policy', component: <Policy /> },
  { to: '/terms', title: 'Terms&Cons', component: <Terms /> },
  { to: '/games', title: 'Games', component: <Games /> }
]


const App = () => {

  return (
    <Router>
      <main className="main" id="top">
        <Navigation pages={pages} />
        <Routes>
          {pages.map((page, index) => <Route key={index} path={page.to} element={page.component} />)}
        </Routes>
      </main>
      <footer>
        <Disclaimer />
        <Footer />
      </footer>
      <ScrollToTop />
      <AgeVerificationPopup />
    </Router>
  );
};

export default App;
