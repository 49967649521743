import React, { useState, useEffect } from "react";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { PopularGames } from "../components/popularGames";
import { Faq } from "../components/faq";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function Home() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

  return (
  <div>
    <Header data={landingPageData} />
    <PopularGames />
    <Faq data={landingPageData.Faq} />
    <Features data={landingPageData.Features} />
  </div>
  )
}

export default Home;