import React from "react";
import { Link } from 'react-router-dom';
import SimpleSlider from "./SimpleSlider";

export const Header = (props) => {
  const data = props.data.Header;
  const games = props.data.Games;

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 intro-text">
                <h1>
                  {data ? data?.title : "Loading"}
                  <span></span>
                </h1>
                <p>{data ? data?.paragraph : "Loading"}</p>
                  <Link className="btn btn-custom btn-lg page-scroll" to="/games">Play now</Link>
              </div>
              <div className="col-md-12">
                <SimpleSlider games={games} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
