import React, { useState, useEffect } from 'react';
import { Game } from './game';

const SimpleSlider = (props) => {
    const games = props.games ? props.games.slice(0, 6) : [];
    const length = games.length;

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 3000); // Автоматична зміна кожні 3 секунди

        return () => clearInterval(interval); // Очищення таймера
    }, [currentIndex]);
    
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (length > 0 ? (prevIndex + 1) % length : 0));
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            length > 0 ? (prevIndex === 0 ? length - 1 : prevIndex - 1) : 0
        );
    };

    const visibleSlides = length > 0
        ? [
            games[currentIndex % length],
            games[(currentIndex + 1) % length],
            games[(currentIndex + 2) % length],
          ]
        : [];

    return (
        <div className="carousel">
            <button className="carousel-button prev" onClick={prevSlide}>
                &#10094;
            </button>
            <div className="carousel-track">
                {visibleSlides ? visibleSlides.map((slide, index) => (
                    <div key={index} className="carousel-slide">
                        <Game title={slide?.title} image={slide?.image} slug={slide?.slug} />
                    </div>
                )): ''}
            </div>
            <button className="carousel-button next" onClick={nextSlide}>
                &#10095;
            </button>
        </div>
    );
};

export default SimpleSlider;
