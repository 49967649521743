import React, { useState, useEffect } from 'react';
import { About } from '../components/about'
import JsonData from "../data/data.json";
import { Features } from '../components/features';

function AboutPage() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <div className="intro page-intro">
        <div className="container">
          <div className="col-md-8">
            <h1>About us</h1>
          </div>
        </div>
      </div>

        <About data={landingPageData.About} />
        <Features data={landingPageData.Features} />
        
    </>

  )
}

export default AboutPage;