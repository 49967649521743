import React, {useState, useEffect} from "react";
import JsonData from "../data/data.json";
import { Game } from "../components/game";

function Games() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>All games</h2>
          <p>
          Welcome to the ultimate collection of free social games on XerPlay! <br/>No downloads, no subscriptions—just pure, risk-free fun directly in your browser. <br/>Find your favorites and start playing today!
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {landingPageData?.Games
              ? landingPageData.Games.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Game
                      title={d.title}
                      image={d.image}
                      slug={d.slug}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games